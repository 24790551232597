import React from 'react'
import video_thumnail from "../assets/images/prob_video_thum.png"

export default function ProblemSolution() {
    return (
        <div className='ProblemSolution_main_class'>
            <h3>Problem Solution</h3>
            <div className="problem_solution_container">
                <div className='problem_solution_video'>
                    <img src={video_thumnail} alt="video thumbnail" />
                </div>
                <div className='problem_solution_text'>
                    <h2 className='problem_solution_title'>
                        Secure Your Workplace with iScreening’s Cutting-Edge Solutions
                    </h2>
                    <p>
                        Devia is your go-to platform for advanced safety management. It combines real-time monitoring,data-driven insights, and efficient communication across departments to create a safer workplace.
                    </p>
                    <div className='a_class_Problem'>
                        <a href="" className="achor_class">
                            <p>
                            Book a demo
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
