import React from 'react'
import OnePlatform_image from "../assets/images/one_platform_img.png"

export default function OnePlatform() {
  return (
    <div className='OnePlatform_main_class'>
        
        <div className='OnePlatform_image_class'>
            <img src={OnePlatform_image} alt="" />
        </div>
        <div className='OnePlatform_text_class'>
            <h2>One Platform, Deeper Insights</h2>
            <p>
            Welcome to Devia where we revolutionize risk management and information sharing across your organization. Our platform seamlessly gathers and analyzes data from third parties delivering clear insights into strengths and vulnerabilities.
            </p>
            <p>
            With just a click of a button, you can customize risk parameters tailored to your unique business needs, putting controldirectly in your hands. After all, no one knows your business better than you. Experience how our platform’s flexibility andease of use can elevate your strategic planning and operational efficiency.
            </p>
            <a href="" className="achor_class">
                <p>
                Book a demo
                </p>
            </a>
        </div>
    </div>
  )
}
