import React from 'react'
import Section_image from "../assets/images/Section_image1.png"
export default function DeviaTextSection() {
  return (
    <div className='DeviaTextSection_main_class'>
        <div className='DeviaTextSection_text_class'>
            <h2>
                Devia,<br />
                A Powerful Platform
            </h2>
            <p>
            that organizes all your data, giving you the freedom to select your customers, partners,distributors, vendors, and employees.
            </p>
            <p>
                Be it Instant Checks,Background Checks or Duo Diligence.
            </p>
            <a href="" className="achor_class">
                <p>
                Book a demo
                </p>
            </a>
        </div>
        <div className='DeviaTextSection_image_class'>
            <img src={Section_image} alt="" />
        </div>
    </div>
  )
}
