import React from 'react'
import videoThumbnail from "../assets/images/video_thumnail.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client_img from "../assets/images/client_img.png"
import NextArrow from "../assets/images/Nextarrow.png"
import PrevArrow from "../assets/images/Prevarrow.png"

export default function ClientSpotlight() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerPadding:"50px",
        responsive: [{
 
            breakpoint: 800,
            settings: {
              slidesToShow: 3,
              infinite: true
            }
       
          }]
    };
    return (
        <div className='ClientSpotlight_main_class'>
            <div className='ClientSpotlight_text_class'>
                <h2 className='ClientSpotlight_text'>CLIENT SPOTLIGHT</h2>
                <Slider {...settings} className='slider_class_container'>
                    <div className='slider_class'>
                        <img src={client_img} alt={"Client_img"} />
                    </div>
                    <div className='slider_class'>
                        <img src={client_img} alt={"Client_img"} />
                    </div>
                    <div className='slider_class'>
                        <img src={client_img} alt={"Client_img"} />
                    </div>
                    <div className='slider_class'>
                        <img src={client_img} alt={"Client_img"} />
                    </div>
                    <div className='slider_class'>
                        <img src={client_img} alt={"Client_img"} />
                    </div>
                    <div className='slider_class'>
                        <img src={client_img} alt={"Client_img"} />
                    </div>
                </Slider>
            </div>

            <div className='safety_container_class'>
                <div className='safety_img_class'>
                    <img src={videoThumbnail} alt={"video thumbnail"} />
                </div>
                <div className='safety_text_class'>
                    <h3>
                        Safety is for Everyone
                    </h3>
                    <p className='safety_p_class_question'>
                        Every day, we make countless decisions. Some big, some small. But how often are these choices truly informed?
                    </p>
                    <p className='safety_p_class'>
                        Whether you’re hiring someone, choosing a partner, or navigating daily choices, every decision has its consequences. That's where we come in to give you the freedom to choose and freedom to design your risk parameters.
                    </p>
                    <p className='safety_p_class'>
                        Devia not only manages the unorganised data, the mundane tasks , the instant checks, but also our domain experts  analyse  and extract valuable insights.
                    </p>
                    <p className='safety_p_class'>
                        Whats most important is the analytics and insights across all departments can be shared at the click of a button enabling informed decision making.
                    </p>
                    <div className='a_class_client'>
                        <a href="" className="achor_class">
                            <p>
                                Know More
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
