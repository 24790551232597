import React from 'react'
import DeviaTextSection from '../components/Devia-text-section'
import OnePlatform from '../components/OnePlatform'
import SixBlockSection from '../components/SixBlockSection'
import SolutionsForEveryBusiness from '../components/SolutionsForEveryBusiness'
import ExcellenceThroughIntegration from '../components/ExcellenceThroughIntegration'
import ClientSpotlight from '../components/ClientSpotlight'
import ProblemSolution from '../components/ProblemSolution'

export default function Home() {
  return (
    <div className='Home_main_class'>
        <DeviaTextSection/>
        <ClientSpotlight/>
        <OnePlatform/>
        <SixBlockSection/>
        <SolutionsForEveryBusiness/>
        <ProblemSolution/>
        <ExcellenceThroughIntegration/>
    </div>
  )
}
