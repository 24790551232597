import React from 'react'
import sale_img from "../assets/images/sale_img.png"
import marketing_img from "../assets/images/marketing_img.png"
import HR_img from "../assets/images/Human_resourse_img.png"
import Finance_img from "../assets/images/finance_img.png"
import Procrument_img from "../assets/images/Procurement_img.png"
import Project_img from "../assets/images/project_management_img.png"
import Li_arrow from "../assets/images/li_arrow.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function SixBlockSection() {
    var settings = {
        // dots: false,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 5,
        // slidesToScroll: 1,
        centerPadding: "50px",
        responsive: [{

            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
            }

        }, {

            breakpoint: 2400,
            settings: "unslick"

        }]
    };
    return (
        <div className='SixBlockSection_main_class'>
            <Slider {...settings} className='slider_class_container SixBlockSection_main_class'>

                <div className="block_container_div">
                    <div className="titl_img_div">
                        <img src={sale_img} alt="" />
                        <h3>Sales</h3>
                    </div>
                    <div className='p_block_container'>
                        <p>Devia: Your all-in-one platform offering 360-degree visibility into critical parameters from on-ground verification to the financial credibility of your trusted partners.</p>
                    </div>
                    <div className='ul_block_container'>
                        <h2>Features</h2>
                        <ul>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Pick Your Reliable Partners</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Financial Stability</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Field Assessment</p></li>
                        </ul>
                        <div className='a_block_container'>
                            <a href="" className="achor_class">
                                <p>
                                    Know More
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block_container_div">
                    <div className="titl_img_div">
                        <img src={marketing_img} alt="" />
                        <h3>Marketing</h3>
                    </div>
                    <div className='p_block_container'>
                        <p>Track loyalty program success and provide actionable insights to the marketing team through feedback.</p>
                    </div>
                    <div className='ul_block_container'>
                        <h2>Features</h2>
                        <ul>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Evaluate Your Loyalty Programs</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Feedback Assessment</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Dashboard and Analytics</p></li>
                        </ul>
                        <div className='a_block_container'>
                            <a href="" className="achor_class">
                                <p>
                                    Know More
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block_container_div">
                    <div className="titl_img_div">
                        <img src={HR_img} alt="" />
                        <h3>Human Resources</h3>
                    </div>
                    <div className='p_block_container'>
                        <p>From senior executives to middle management, evaluate cultural fit and performance.Tailored background checks which cover every role from blue-collared to white-collared employees.</p>
                    </div>
                    <div className='ul_block_container'>
                        <h2>Features</h2>
                        <ul>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Employee Background Checks</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Discreet Checks</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Audited Reports and Real-Time Updates</p></li>
                        </ul>
                        <div className='a_block_container'>
                            <a href="" className="achor_class">
                                <p>
                                    Know More
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block_container_div">
                    <div className="titl_img_div">
                        <img src={Finance_img} alt="" />
                        <h3>Finance</h3>
                    </div>
                    <div className='p_block_container'>
                        <p>Get started by accessing your document vault, tailored for all the  departments, and customize your risk checklist to fit your needs.</p>
                    </div>
                    <div className='ul_block_container'>
                        <h2>Features</h2>
                        <ul>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Integrated Platform</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />StepIn,Upload,and Transform Your Document Management </p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Customer Risk Score</p></li>
                        </ul>
                        <div className='a_block_container'>
                            <a href="" className="achor_class">
                                <p>
                                    Know More
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block_container_div">
                    <div className="titl_img_div">
                        <img src={Procrument_img} alt="" />
                        <h3>Procurement</h3>
                    </div>
                    <div className='p_block_container'>
                        <p>Control off-contract spending and ensuredeliverables by enabling vendor selection with customized score cards.</p>
                    </div>
                    <div className='ul_block_container'>
                        <h2>Features</h2>
                        <ul>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Supplier Selection</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Conquer- ff Contract Spends</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Measurement of Supplier Performance</p></li>
                        </ul>
                        <div className='a_block_container'>
                            <a href="" className="achor_class">
                                <p>
                                    Know More
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="block_container_div">
                    <div className="titl_img_div">
                        <img src={Project_img} alt="" />
                        <h3>Project Management</h3>
                    </div>
                    <div className='p_block_container'>
                        <p>Get instant KYC reports for your business partners.Also continuous monitoring with real-time alerts  for the triggers and dataset combination customized by you.</p>
                    </div>
                    <div className='ul_block_container'>
                        <h2>Features</h2>
                        <ul>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Instant KYC Insights </p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Custom Filters & Triggers</p></li>
                            <li className='li_block_container'><p><img src={Li_arrow} alt={"arrow"} />Proactive Monitoring and Alerts</p></li>
                        </ul>
                        <div className='a_block_container'>
                            <a href="" className="achor_class">
                                <p>
                                    Know More
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </Slider>

        </div>
    )
}
