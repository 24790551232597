import React from 'react'
import Iscreen_logo from "../assets/images/Iscreening_logo.png";
import MobileMenu from './MobileMenu';
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
    return (<>
        <div className='Header_main_class'>
            <div className='Header_logo_class'>
                <img src={Iscreen_logo} alt="" />
            </div>
            <div className='Header_nav_class'>
                <ul>
                    <li>
                        <a href="">
                            Departments
                        </a>
                    </li>
                    <li>
                        <a href="">
                            Solutions
                        </a>
                    </li>
                    <li>
                        <a href="">
                            Resources
                        </a>
                    </li>
                    <li>
                        <a href="">
                            About US
                        </a>
                    </li>
                </ul>
            </div>
            <div className='Header_login_class'>
                <ul>
                    <li><a href="">Book a demo</a></li>
                    <li><a href="">Login</a></li>
                </ul>
            </div>
            <div className='Header_menu_Hamburger'>
                <MobileMenu />
            </div>
        </div>
    </>
    )
}
