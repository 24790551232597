import React from 'react'
import blog1 from '../assets/images/blog1.png'
import blog2 from '../assets/images/blog2.png'
import blog3 from '../assets/images/blog3.png'
import blog4 from '../assets/images/blog4.png'

export default function ExcellenceThroughIntegration() {
  return (
    <div className='ExcellenceThroughIntegration_main_class'>
        <div className='ExcellenceThroughIntegration_text_class'>
            <h1>Excellence through integration</h1>
            <p>See how businesses like yours are transforming safety with iScreening. Our clients personalize risk assessments, focusing on what matters most to them.</p>
            <div className='a_class'>
                <a href="" className="achor_class">
                    <p>
                        Know More
                    </p>
                </a>
            </div>
        </div>

        <div className='popular_blog_container'>
            <div className="pop_blog_tit">
                <h2>Popular blog posts</h2>
            </div>
            <div className='popular_blog_divs_container'>
                <div className='popular_blog_div'>
                    <div>
                        <img src={blog1} alt={"blog image"} />
                    </div>
                    <p>
                    Dummy CopyDummy Copy Dummy CopyDummy Copy
                    </p>
                </div>
                <div className='popular_blog_div'>
                    <div>
                        <img src={blog2} alt={"blog image"} />
                    </div>
                    <p>
                    Dummy CopyDummy Copy Dummy CopyDummy Copy
                    </p>
                </div>
                <div className='popular_blog_div'>
                    <div>
                        <img src={blog3} alt={"blog image"} />
                    </div>
                    <p>
                    Dummy CopyDummy Copy Dummy CopyDummy Copy
                    </p>
                </div>
                <div className='popular_blog_div'>
                    <div>
                        <img src={blog4} alt={"blog image"} />
                    </div>
                    <p>
                    Dummy CopyDummy Copy Dummy CopyDummy Copy
                    </p>
                </div>
            </div>
        </div>

        <div className='we_support_container'>
            <h3>We Support All Businesses </h3>
            <p>iScreening is committed to helping you enhance your safety protocols, whether you’re a current client or not</p>
        </div>

    </div>
  )
}
