import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Iscreen_logo from "../assets/images/Iscreening_logo.png";
import End_logo from "../assets/images/end_arrow.png";

// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

export default function MobileMenu() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation"  >
            <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
                <CloseIcon style={{ cursor: "pointer" }} onClick={toggleDrawer(false)} />
            </div>

            <div className='mobile_menu_logo'>
                <img src={Iscreen_logo} alt="" />
            </div>
            <List>
                {['Departments', 'Solutions', 'Resources', 'About US', 'Book a demo', 'Login'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            {/* <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon> */}
                            <ListItemText className={text == 'Book a demo' ? "book_class" : 'text_class'} primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <div className='mobile_end_arrow'>
                <img src={End_logo} alt="" />
            </div>
        </Box>
    );
    return (
        <div className='mobile_menu_drawer'>
            <Button color='black' onClick={toggleDrawer(true)}><MenuIcon /></Button>

            <Drawer open={open} className='mobileDrawer' onClose={toggleDrawer(false)}>

                {DrawerList}
            </Drawer>
        </div>
    )
}
