import React from 'react'
import Instant_checks from '../assets/images/instant_chhecks.png'
import BG_checks from '../assets/images/bg_checks.png'
import Due_Diligence from '../assets/images/due_diligence.png'

export default function SolutionsForEveryBusiness() {
    return (
        <div className='SolutionsForEveryBusiness_main_class'>
            <h2 className='SolutionsForEveryBusiness_text_class'>Solutions for every business</h2>
            <div className='solutions_div_container'>
                <div className='solutions_container'>
                    <div className='solutions_img_container'>
                        <img src={Instant_checks} alt={Instant_checks} />
                    </div>
                    <div className='solutions_text_container'>
                        <h2>Instant Checks</h2>
                        <p>Quickly verify key safety parameters Real-time monitoring and alerts</p>
                    </div>
                    <div className='solutions_a_container'>
                        <a href="" className="achor_class">
                            <p>
                                Know More
                            </p>
                        </a>
                    </div>
                </div>

                <div className='solutions_container'>
                    <div className='solutions_img_container'>
                        <img src={BG_checks} alt={BG_checks} />
                    </div>
                    <div className='solutions_text_container'>
                        <h2>Background Checks</h2>
                        <p>Comprehensive analysis of background information</p>
                        <p>Thorough vetting for enhanced security</p>
                    </div>
                    <div className='solutions_a_container'>
                        <a href="" className="achor_class">
                            <p>
                                Know More
                            </p>
                        </a>
                    </div>
                </div>

                <div className='solutions_container'>
                    <div className='solutions_img_container'>
                        <img src={Due_Diligence} alt={Due_Diligence} />
                    </div>
                    <div className='solutions_text_container'>
                        <h2>Due Diligence</h2>
                        <p>Detailed examination of risk factors</p>
                        <p>In-depth analysis of variables for informed decision-making</p>
                    </div>
                    <div className='solutions_a_container'>
                        <a href="" className="achor_class">
                            <p>
                                Know More
                            </p>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}
