import React from 'react'
import Free_con from "../assets/images/free_consult.png"
import Free_trial from "../assets/images/free_trail.png"
import DIY_reso from "../assets/images/DIY_reso.png"
import case_stu from "../assets/images/case_study.png"
import support from "../assets/images/24x7.png"
import Easy_digital from "../assets/images/easy_digital.png"
import Footer_img from "../assets/images/footer_img.png"
import Fb_icon from "../assets/images/fb_icon.png"
import X_icon from "../assets/images/X_icon.png"
import IN_icon from "../assets/images/in_icon.png"

export default function Footer() {
  return (
    <div className='Footer_main_class'>
      <div className='footer_six_block_container'>
        <div className='footer_six_block'>
          <div className='footer_six_block_img'>
            <img src={Free_con} alt="img_icon" />
            <h3>Free Consultation</h3>
          </div>
          <p>Our experienced team offers free consultations to help identify your needs and design a custom program.</p>
        </div>
        <div className='footer_six_block'>
          <div className='footer_six_block_img'>
            <img src={Free_trial} alt="img_icon" />
            <h3>Free Trial</h3>
          </div>
          <p>Try our services at no cost to assess our SLA and quality.</p>
        </div>
        <div className='footer_six_block'>
          <div className='footer_six_block_img'>
            <img src={DIY_reso} alt="img_icon" />
            <h3>DIY Resources</h3>
          </div>
          <p>Access our guides and templates to conduct your own safety checks.</p>
        </div>
        <div className='footer_six_block'>
          <div className='footer_six_block_img'>
            <img src={case_stu} alt="img_icon" />
            <h3>Case Studies</h3>
          </div>
          <p>Learn from our comprehensiv case studies.</p>
        </div>
        <div className='footer_six_block'>
          <div className='footer_six_block_img'>
            <img src={support} alt="img_icon" />
            <h3>24x7 Support</h3>
          </div>
          <p>Benefit from continuous customer support.</p>
        </div>
        <div className='footer_six_block'>
          <div className='footer_six_block_img'>
            <img src={Easy_digital} alt="img_icon" />
            <h3>Easy Digital Onboarding</h3>
          </div>
          <p>Quickly onboard as a client through our streamlined digital process.</p>
        </div>
      </div>

      <div className='footer_menu_class'>
        <div className='footer_address_section'>
          <h3>iScreening PVT. LTD.</h3>
          <p>
            Dummycopy Dummycopy Dummycopy Dummycopy Dummycopy
          </p>
        </div>
        <div className='footer_menu_section'>
          <h3>OUR COMPANY</h3>
          <ul>
            <li>
              <a href="">Careers</a>
            </li>
            <li>
              <a href="">About Us</a>
            </li>
            <li>
              <a href="">Our Team</a>
            </li>
            <li>
              <a href="">Terms & Conditions</a>
            </li>
          </ul>
        </div>
        <div className='footer_btn_img_section'>
          <img src={Footer_img} alt="img_icon" />
          <div class="a_class_Problem"><a href="" class="achor_class"><p>Book a demo</p></a></div>
        </div>
      </div>

      <div className='footer_bar_section'>
        <div className='call_div'>
          <p>
            <a href="">
            CALL:. 1800-333-0555
            </a>
          </p>
        </div>
        <div className='social_div'>
          <p>Follow us on:</p>
          <ul>
          <li><a href=""><img src={Fb_icon} alt="" /></a></li>
          <li><a href=""><img src={X_icon} alt="" /></a></li>
          <li><a href=""><img src={IN_icon} alt="" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}
